<template>
  <div class="start">
    <Header />
    <div class="start-form">
      <div class="container">
        <div class="warpper">
          <div class="title" v-if="type != 3">SELL YOUR CAR FAST</div>
          <div class="desc" v-if="type == 2">
            <div class="info">
              My car has done {{ ruleForm.kilometres }} km and was manufactured
              in {{ ruleForm.year }}.
            </div>
            <div class="info">
              You’re almost there! Just a few more details.
            </div>
          </div>
          <div class="desc" v-if="type == 1">
            <div class="info">
              To begin, please enter details about your car...
            </div>
          </div>
          <div class="form" v-if="type == 2">
            <el-form :model="carForm" :rules="carFormRule" ref="carForm">
              <div class="car-detail">
                <div class="title">CAR DETAILS</div>
                <div class="form-item">
                  <el-form-item>
                    <el-input
                      v-model="carForm.plate_number"
                      type="text"
                      placeholder="Rego.(optional)"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item prop="make">
                    <el-select
                      v-model="carForm.make"
                      placeholder="Make*"
                      no-data-text="No data"
                      class="select"
                      ref="select"
                      :popper-append-to-body="false"
                      :disabled="makeList.length == 0"
                      @change="makeChange"
                      filterable
                      @hook:mounted="cancalReadOnly"
                      @visible-change="cancalReadOnly"
                    >
                      <el-option
                        v-for="(item, index) in makeList"
                        :key="index"
                        :label="item.name"
                        :value="item.alias"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item prop="model">
                    <el-select
                      v-model="carForm.model"
                      placeholder="Model*"
                      no-data-text="No data"
                      class="select"
                      :disabled="!carForm.make"
                      :popper-append-to-body="false"
                      @change="modelChange"
                    >
                      <el-option
                        v-for="(item, index) in modelList"
                        :key="index"
                        :label="item.name"
                        :value="item.alias"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item prop="variant">
                    <el-select
                      v-model="carForm.variant"
                      placeholder="Variant*"
                      no-data-text="No data"
                      class="select"
                      :popper-append-to-body="false"
                      :disabled="!carForm.model"
                    >
                      <el-option
                        v-for="(item, index) in variantList"
                        :key="index"
                        :value="item.alias"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="remark">
                  <div class="checkbox">
                    <el-checkbox v-model="checked"
                      >Additional details about your car</el-checkbox
                    >
                  </div>
                  <div class="textear" v-show="checked">
                    <el-input
                      type="textarea"
                      :rows="4"
                      v-model="carForm.remark"
                    >
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="your-detail">
                <div class="title">YOUR DETAILS</div>
                <div class="form-item">
                  <el-form-item prop="name">
                    <el-input
                      v-model="carForm.name"
                      type="text"
                      placeholder="Name*"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item prop="phone">
                    <el-input
                      v-model="carForm.phone"
                      type="text"
                      placeholder="Phone*"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item prop="email">
                    <el-input
                      v-model="carForm.email"
                      type="text"
                      placeholder="Email*"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item prop="postcode">
                    <el-input
                      v-model="carForm.postcode"
                      type="text"
                      placeholder="Postcode*"
                    ></el-input>
                  </el-form-item>
                </div>

                <div class="form-item">
                  <el-form-item>
                    <el-select
                      v-model="carForm.sell_time"
                      placeholder="When do you plan to sell your car?*"
                      no-data-text="No data"
                      class="select"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="(item, index) in sell_timeList"
                        :key="index"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item>
                    <el-select
                      v-model="carForm.hearAbout"
                      placeholder="How did you hear about us?"
                      no-data-text="No data"
                      class="select"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="(item, index) in hearAboutList"
                        :key="index"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <div class="btns" v-if="type == 2">
              <div class="btn2" v-if="type == 2" @click="back">Back</div>
              <div class="btn1" v-if="type == 2" @click="handelSubmit">
                Submit
              </div>
            </div>
          </div>
          <div class="form" v-if="type == 1">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <div class="form-item">
                <el-form-item prop="kilometres">
                  <el-input
                    v-model="ruleForm.kilometres"
                    type="text"
                    placeholder="Kilometres driven*"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <el-form-item prop="year">
                  <el-input
                    v-model="ruleForm.year"
                    type="text"
                    placeholder="Year manufactured*"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="subimit" @click="handleContinue">Continue</div>
          </div>
          <div class="success" v-if="type == 3">
            <div class="title">WE ARE READY TO BUY</div>
            <div class="desc">
              We’ll evaluate your car details and respond within 24 hours with a
              quote. An email confirmation has also been sent for your records.
            </div>
            <div class="line"></div>
            <div class="title2">Here is your reference number:</div>
            <div class="code">{{ code }}</div>
            <div class="line"></div>
            <div class="title2">
              If you have any questions, please contact us on:
            </div>
            <div class="num">1300 849 879</div>
            <div class="line"></div>
            <div class="title2">WHY SELL WITH US?</div>
            <div class="info">We pay within 24 hours No hidden fees</div>
            <div class="info">
              We’re a team of professionals It’s quick and easy
            </div>
            <div class="info">We’ll even pick up your car for free!</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import fromRules from "@/utils/fromRules";
import { makes, models, variants, submit } from "@/api/start";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      ruleForm: {
        kilometres: "",
        year: "",
      },
      rules: {
        kilometres: [
          {
            required: true,
            validator: fromRules.validateNumber,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
        year: [
          {
            required: true,
            validator: fromRules.validateNumber,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
      },
      type: 3,
      carForm: {
        name: "",
        phone: "",
        email: "",
        postcode: "",
        make: "",
        remark: "",
        model: "",
        variant: "",
        hearAbout: "",
        plate_number: "",
        sell_time: "",
      },
      carFormRule: {
        make: [
          {
            required: true,
            message: "Please select a make",
            trigger: "change",
          },
        ],
        model: [
          {
            required: true,
            message: "Please select a model",
            trigger: "change",
          },
        ],
        variant: [
          {
            required: true,
            message: "Please select a variant",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: fromRules.validateNumber,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
        postcode: [
          {
            required: true,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
      },
      checked: false,
      makeList: [],
      modelList: [],
      variantList: [],
      hearAboutList: [
        "Word of mouth",
        "Radio",
        "Billboards",
        "Searching through Google",
        "Social media (Facebook, Instagram)",
        "None of the above",
      ],
      code: "",
      sell_timeList: ["Urgent", "1-2 Weeks", "Next Month"],
    };
  },
  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs;
          const input = select.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
        }
      });
    },
    handleContinue() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.kilometres >= 150000 || this.ruleForm.year < 2012) {
            this.$alert(
              "*No older than 2012 with less than 150,000kms",
              "Tips",
              {
                confirmButtonText: "Confirm",
                confirmButtonClass: "confirm-button",
                customClass: "my-message",
              }
            );
          } else if (this.ruleForm.year > new Date().getFullYear()) {
            this.$alert(
              `*No more than ${new Date().getFullYear()} with less than 150,000kms`,
              "Tips",
              {
                confirmButtonText: "Confirm",
                confirmButtonClass: "confirm-button",
                customClass: "my-message",
              }
            );
          } else {
            sessionStorage.setItem("startInfo", JSON.stringify(this.ruleForm));
            this.type = 2;
            this.getMakes();
          }
        }
      });
    },
    back() {
      this.type = 1;
      window.scrollTo(0, 0);
    },
    getMakes() {
      makes({
        year: this.ruleForm.year,
      }).then((res) => {
        if (res.code == 0) {
          this.makeList = res.data;
        }
      });
    },
    makeChange(val) {
      models({
        year: this.ruleForm.year,
        make: val,
      }).then((res) => {
        if (res.code == 0) {
          this.modelList = res.data;
        }
      });
    },
    modelChange(val) {
      variants({
        year: this.ruleForm.year,
        make: this.carForm.make,
        model: val,
      }).then((res) => {
        if (res.code == 0) {
          this.variantList = res.data;
        }
      });
    },
    handelSubmit() {
      this.$refs.carForm.validate((valid) => {
        if (valid) {
          submit({
            variant: this.carForm.variant,
            year: this.ruleForm.year,
            mileage: this.ruleForm.kilometres,
            name: this.carForm.name,
            phone: this.carForm.phone,
            email: this.carForm.email,
            remark: this.carForm.remark,
            postcode: this.carForm.postcode,
            from_channel: this.carForm.hearAbout,
            site: "main",
            plate_number: this.carForm.plate_number,
            make: this.carForm.make,
            model: this.carForm.model,
            sell_time: this.carForm.sell_time,
          }).then((res) => {
            if (res.code === 0) {
              this.code = res.data.confirm_code;
              window.scrollTo(0, 0);
              this.type = 3;
              sessionStorage.removeItem("startInfo");
            }
          });
        }
      });
    },
  },
  created() {
    const startInfo = JSON.parse(sessionStorage.getItem("startInfo"));
    if (startInfo) {
      this.ruleForm = startInfo;
      this.type = 2;
      this.getMakes();
    } else {
      this.type = 1;
    }
  },
};
</script>
<style lang="less">
.confirm-button {
  background-color: #f5c03e !important;
  border-color: #f5c03e !important;
  // font-weight: b;
  font-family: Poppins-SemiBold !important;
}
.my-message {
  width: auto !important;
  font-family: Poppins-SemiBold !important;
}
</style>

<style scoped lang="less">
/deep/ .el-scrollbar__view {
  list-style: none;
  padding: 6px 0;
  margin: 0;
  box-sizing: border-box;
}
/deep/ .el-select-dropdown__item.selected {
  color: #f5c03e;
  font-family: Poppins-SemiBold;
}

/deep/ .el-select-dropdown__item {
  font-family: Poppins-SemiBold;
  font-size: 14px;
}
/deep/ .el-select-dropdown__empty {
  color: #f5c03e;
  font-family: Poppins-SemiBold;
}
.start {
  width: 100%;
  position: relative;
  padding-top: 120px;
  .start-form {
    width: 100%;
    padding-top: 67px;
    padding-bottom: 67px;
    box-sizing: border-box;
    background: #f5c03e;
    .warpper {
      .title {
        font-family: Poppins-Black;
        font-size: 40px;
        color: #010101;
        line-height: 47px;
        text-align: center;
        font-weight: 900;
        margin-bottom: 20px;
      }
      .desc {
        font-family: Poppins-Regular;
        text-align: center;
        color: rgba(0, 0, 0, 0.7);
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .form {
        width: 100%;
        margin-bottom: 60px;
        font-family: Poppins-Regular;
        .subimit {
          width: 480px;
          padding: 15px;
          box-sizing: border-box;
          background: #3b3b3b;
          text-align: center;
          font-family: Poppins-SemiBold;
          font-weight: 600;
          border-radius: 6px;
          font-size: 14px;
          line-height: 16px;
          user-select: none;
          cursor: pointer;
          transition: all 0.3s;
          color: #f5c03e;
          text-align: center;
          margin: 0 auto;
          margin-top: 40px;
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
          }
        }
        .form-item {
          width: 480px;
          margin: 0 auto;
          margin-bottom: 16px;
          /deep/ .el-input__inner {
            -web-kit-appearance: none;
            -moz-appearance: none;
            outline: 0;
            width: 100%;
            padding: 10px 20px;
            background: #f5f5f5;
            border: 1px solid transparent;
            color: #212121;
            box-sizing: border-box;
            font-family: Poppins-Regular;
            &:focus {
              border: 1px solid #010101;
            }
          }
          /deep/ .select {
            width: 100%;
            background: #f5f5f5;
            border-radius: 4px;
            font-family: Poppins-Regular;
            .el-input__inner {
              border: none;
              font-family: Poppins-Regular;
            }
          }
          /deep/ .el-input.is-disabled .el-input__inner {
            background: #e0e0e0;
            font-family: Poppins-Regular;
          }
        }
        .remark {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          margin-bottom: 16px;
          font-family: Poppins-SemiBold;
          .checkbox {
            margin-bottom: 16px;
          }
          .el-checkbox {
            color: #010101;
          }
          /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #010101;
          }
          /deep/ .el-checkbox__inner {
            border-color: #010101;
          }
          /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #010101;
          }
          /deep/ .el-textarea__inner {
            width: 480px;
            font-family: Poppins-Regular;
          }
          /deep/ .el-textarea__inner:focus {
            border-color: #010101;
          }
        }
        .car-detail {
          .title {
            font-family: Poppins-ExtraBold;
            font-size: 24px;
            color: #212121;
            line-height: 38px;
            text-align: center;
            font-weight: 800;
            margin-bottom: 20px;
          }
        }
        .your-detail {
          .title {
            font-family: Poppins-ExtraBold;
            font-size: 24px;
            color: #212121;
            line-height: 38px;
            text-align: center;
            font-weight: 800;
            margin-bottom: 20px;
          }
          .form-item {
            width: 480px;
            margin: 0 auto;
            margin-bottom: 16px;
            input {
              -web-kit-appearance: none;
              -moz-appearance: none;
              outline: 0;
              width: 100%;
              padding: 10px 20px;
              background: #f5f5f5;
              border: 1px solid transparent;
              color: #212121;
              font-family: Poppins-Regular;
              &:focus {
                border: 1px solid #010101;
              }
            }
            select {
              width: 100%;
              border: solid 1px transparent;
              padding: 10px 20px;
              background: #f5f5f5;
              &:focus {
                border: 1px solid #010101;
              }
              option::-ms-expand {
                display: none;
              }
              option {
                -moz-appearance: none; /* Firefox */
                -webkit-appearance: none; /* Safari 和 Chrome */
                appearance: none;
                height: 125px;
              }
            }
          }
        }
        .btns {
          display: flex;
          justify-content: center;
          margin-top: 60px;
          .btn1,
          .btn2 {
            width: 228px;
            padding: 15px;
            box-sizing: border-box;
            background: #3b3b3b;
            text-align: center;
            font-family: Poppins-SemiBold;
            font-weight: 600;
            border-radius: 6px;
            font-size: 14px;
            line-height: 16px;
            user-select: none;
            cursor: pointer;
            transition: all 0.3s;
            color: #f5c03e;
            text-align: center;
            margin-bottom: 16px;
            &:hover {
              transform: translateY(-5px);
              box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
            }
          }
          .btn2 {
            background: #fff;
            color: #3b3b3b;
            margin-right: 24px;
          }
        }
      }

      .success {
        padding: 0 200px;
        box-sizing: border-box;
        .title {
          font-size: 40px;
          font-family: Poppins-Black;
          font-weight: 900;
          color: #222222;
          line-height: 47px;
          margin-bottom: 24px;
        }
        .desc {
          font-size: 16px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7200000286102295);
          line-height: 19px;
          margin-bottom: 24px;
        }
        .code {
          font-size: 64px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #222222;
          line-height: 75px;
          text-align: center;
          margin-bottom: 24px;
        }
        .num {
          font-size: 16px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.66);
          line-height: 19px;
          text-align: center;
          margin-bottom: 24px;
        }
        .title2 {
          font-size: 20px;
          font-family: Lato-Heavy, Lato;
          font-weight: 800;
          color: #212121;
          line-height: 23px;
          text-align: center;
          margin-bottom: 24px;
        }
        .info {
          font-size: 16px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.66);
          line-height: 19px;
          text-align: left;
          position: relative;
          margin-bottom: 24px;
          text-align: center;
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            background: #393939;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -16px;
          }
        }
        .line {
          width: 100%;
          height: 1px;
          background: rgba(0, 0, 0, 0.23999999463558197);
          margin-bottom: 24px;
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  /deep/ .el-select-dropdown__item {
    // font-family: Poppins-Black;
    font-size: 14px;
    width: 320px;
  }
  .start {
    padding-top: 20px;
    .start-form {
      padding-bottom: 20px;
      .warpper {
        .title {
          font-size: 24px;
          line-height: 28px;
        }
        .desc {
          .info {
            font-size: 12px;
            line-height: 14px;
          }
        }
        .form {
          .subimit {
            width: 100%;
            box-sizing: border-box;
          }
          .form-item {
            width: 100%;
          }
          .remark {
            align-items: flex-start;
            /deep/ .el-textarea__inner {
              width: 320px;
            }
          }
          .car-detail {
            .title {
              font-size: 16px;
              line-height: 19px;
            }
          }
          .your-detail {
            .title {
              font-size: 16px;
              line-height: 19px;
            }
            .form-item {
              width: 100%;
            }
          }
          .btns {
            margin-bottom: 120px;
          }
        }
        .success {
          padding: 0;
          .code {
            font-size: 40px;
            line-height: 47px;
          }
          .title2 {
            font-size: 16px;
            line-height: 19px;
          }
          .title {
            font-size: 24px;
            line-height: 28px;
          }
          .desc {
            font-size: 12px;
            line-height: 14px;
          }
          .num {
            font-size: 12px;
            line-height: 14px;
          }
          .info {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 690px) and (max-width: 999px) {
  /deep/ .el-select-dropdown__item {
    // font-family: Poppins-Black;
    font-size: 14px;
    width: 320px;
  }
  .start {
    padding-top: 20px;
    .start-form {
      padding-bottom: 20px;
      .warpper {
        .title {
          font-size: 24px;
          line-height: 28px;
        }
        .desc {
          .info {
            font-size: 12px;
            line-height: 14px;
          }
        }
        .form {
          .subimit {
            width: 100%;
            box-sizing: border-box;
          }
          .form-item {
            width: 100%;
          }
          .remark {
            align-items: flex-start;
            /deep/ .el-textarea__inner {
              width: 320px;
            }
          }
          .car-detail {
            .title {
              font-size: 16px;
              line-height: 19px;
            }
          }
          .your-detail {
            .title {
              font-size: 16px;
              line-height: 19px;
            }
            .form-item {
              width: 100%;
            }
          }
        }
        .success {
          padding: 0;
          .code {
            font-size: 40px;
            line-height: 47px;
          }
          .title2 {
            font-size: 16px;
            line-height: 19px;
          }
          .title {
            font-size: 24px;
            line-height: 28px;
          }
          .desc {
            font-size: 12px;
            line-height: 14px;
          }
          .num {
            font-size: 12px;
            line-height: 14px;
          }
          .info {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
}
</style>
