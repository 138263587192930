import {
    request
} from "./request";


// 获取品牌
export const makes = (data) => {
    return request({
        url: '/apply/get-makes',
        method: 'post',
        data
    })
}

// 获取系列
export const models = (data) => {
    return request({
        url: '/apply/get-models',
        method: 'post',
        data
    })
}

// 获取款式
export const variants = (data) => {
    return request({
        url: '/apply/get-variants',
        method: 'post',
        data
    })
}

// 提交信息
export const submit = (data) => {
    return request({
        url: '/apply/submit',
        method: 'post',
        data
    })
}